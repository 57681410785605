<template>
  <div>
    <div class="mb-2">Киев</div>
    <v-row class="text-center">
      <v-col cols="3">
        <div class="caption mb-1">Сейчас</div>
        <v-img
          src="assets2/static/weather/cloudy.gif"
          max-height="50"
          max-width="50"
          class="mx-auto"
        ></v-img>
        <div class="caption">0°C <span class="mx-1">|</span> -5°C</div>
      </v-col>
      <v-col cols="3">
        <div class="caption mb-1">Четверг</div>
        <v-img
          src="assets2/static/weather/chanceflurries.gif"
          max-height="50"
          max-width="50"
          class="mx-auto"
        ></v-img>
        <div class="caption">0°C <span class="mx-1">|</span> -5°C</div>
      </v-col>
      <v-col cols="3">
        <div class="caption mb-1">Пятница</div>
        <v-img
          src="assets2/static/weather/chanceflurries.gif"
          max-height="50"
          max-width="50"
          class="mx-auto"
        ></v-img>
        <div class="caption">0°C <span class="mx-1">|</span> -5°C</div>
      </v-col>
      <v-col cols="3">
        <div class="caption mb-1">Суббота</div>
        <v-img
          src="assets2/static/weather/chanceflurries.gif"
          max-height="50"
          max-width="50"
          class="mx-auto"
        ></v-img>
        <div class="caption">0°C <span class="mx-1">|</span> -5°C</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    edit() {
      console.log(1243);
    },
  },
  computed: {
    dotsNav() {
      return [{ title: "Настройки", icon: "mdi-cogs", func: this.edit }];
    },
  },
};
</script>